import { createGlobalStyle } from 'styled-components'
import { PancakeTheme } from '@pancakeswap/uikit'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face
  {
    font-family: 'NowAlt-Medium';
    src: url(/fonts/NowAlt-Medium.otf) format('opentype');
  }
  body {
    background-image: url("/images/bkg.png");
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
