import { BigNumber } from '@ethersproject/bignumber'
import Trans from 'components/Trans'
import { VaultKey } from 'state/types'
import { CHAIN_ID } from './networks'
import tokens, { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto CAKE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/croshi.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake CAKE</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/croshi.png`,
      secondarySrc: '/images/tokens/autorenew.svg',
    },
  },
 /* [VaultKey.IfoPool]: {
    name: 'IFO CAKE',
    description: <Trans>Stake CAKE to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${tokens.cake.address}.svg`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },*/
} as const

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.croshi,
    earningToken: serializedTokens.croshi,
    contractAddress: {
      97: '0xDf42B006825b432b839794e8bA47F206296620e2',
      25: '0xDf42B006825b432b839794e8bA47F206296620e2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '100',
    sortOrder: 1,
    isFinished: false,
    length: "Forever"
  },
  {
    sousId: 1,
    stakingToken: serializedTokens.croshi,
    earningToken: serializedTokens.croshi,
    contractAddress: {
      97: '0x4519EBD8Ed61Cf3263bcbFfD48D228f4ADb8c9A9',
      25: '0x4519EBD8Ed61Cf3263bcbFfD48D228f4ADb8c9A9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '2.7255',
    sortOrder: 1,
    isFinished: false,
    length: "10 WEEKS"
  
  },


].filter((p) => !!p.contractAddress[CHAIN_ID])

export default pools

